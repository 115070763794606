<template>
  <el-row justify="center" class="mt-50">
    <el-col :span="18">
      
    
      <el-button type="primary" style="margin-left: 16px" @click="drawer = true">
            <el-icon>
              <User />
             
            </el-icon>
            Click To Show Menu
          </el-button>

          <el-drawer v-model="drawer" title="User Dashboard" size="70%" :with-header="false">
            <el-card class="box-card" shadow="never">
              <div class="text-center">
                <el-avatar v-if="authUser.image" :size="40" class="user_avatar">
                  <img :src="userPhoto(authUser.image)" alt="Bd Royel Culture" />
                </el-avatar>
                <el-avatar v-else :size="40" class="user_avatar">
                  <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                    alt="Bd Royel Culture" />
                </el-avatar>
                <br />
                <br />
                <h4> {{ authUser ? authUser.name : 'Guest' }}</h4>
                <p> {{ authUser ? authUser.email : '' }}</p>
              </div>
              <br />
              <hr class="mt-5">
              <div class="user-menu">
                <el-menu default-active="1" class="el-menu-vertical-demo" :ellipsis="false" :router="true">

                  <el-menu-item index="/user/dashboard">
                    <el-icon>
                      <Grid />
                    </el-icon>
                    <span>Dashboard</span>
                  </el-menu-item>

                  <el-menu-item index="/user/order/list">
                    <el-icon>
                      <Tickets />
                    </el-icon>
                    <span>Purchase History</span>
                  </el-menu-item>

                  <el-menu-item index="/user/profile">
                    <el-icon>
                      <User />
                    </el-icon>
                    <span>Manage Profile</span>
                  </el-menu-item>

                  <el-menu-item index="/login">
                    <el-icon>
                      <RemoveFilled />
                    </el-icon>
                    <span>Logout</span>
                  </el-menu-item>

                </el-menu>
              </div>
            </el-card>
          </el-drawer>
          <router-view></router-view>
      
     
    </el-col>

  </el-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters('auth', ['authUser', 'loggedIn']),
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style scoped>
.mt-50 {
  margin-top: 50px;
}
</style>
<style>
.user-menu .el-menu {
  border-right: 0px !important;
}
</style>
